import React, { useState } from 'react';

function HeroSection() {
  const [translate, setTranslate] = useState({ x1: 0, y1: 0, x2: 0, y2: 0 });

  const handleMouseMove = (e, divNumber) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;
    const distanceFactor = 300; // Adjust this value to change the sensitivity

    if (divNumber === 1) {
      setTranslate({ ...translate, x1: x / distanceFactor, y1: y / distanceFactor });
    } else {
      setTranslate({ ...translate, x2: x / distanceFactor, y2: y / distanceFactor });
    }
  };
  
  return (
    <div
      className="relative flex flex-col md:flex-row md:h-screen justify-center items-center pt-8 md:pt-0 md:px-16"
      onMouseMove={(e) => handleMouseMove(e, 1)}
    >
      <div className='z-0 w-full h-full absolute top-0 left-0 tokyo-background'></div>

      <div className='w-full px-16 md:px-0 md:w-1/2 lg:w-[34rem] xl:w-[40rem] h-2/5 md:h-3/5 lg:h-4/5 2xl:h-3/5 mb-8 md:mb-16 animate-bounce-slow animate-neon-flicker'>
        <div
          className='w-full h-full'
          style={{ transform: `translate(${translate.x1}px, ${translate.y1}px)` }}
        >
          <div className='w-full h-full flex justify-center overflow-hidden -skew-y-3 -skew-x-3 md:skew-y-6 md:-skew-x-6 glow'>
            <img src="/pic.jpg" alt="Luca Dasic" className="w-full h-full object-cover object-top scale-110 md:scale-[120%] skew-y-3 skew-x-3 md:-skew-y-6 md:skew-x-6" />
          </div>
        </div>
      </div>

      <div className='w-full px-16 md:px-0 md:w-1/2 lg:w-[34rem] xl:w-[40rem] grow md:grow-0 md:h-3/5 lg:h-4/5 2xl:h-3/5 md:ml-8'>
        <div
          className='w-full h-full'
          style={{ transform: `translate(${translate.x1}px, ${translate.y1}px)` }}
        >
          <div className="w-full h-full py-8 md:py-0 bg-white -skew-x-3 -skew-y-3 md:-skew-x-6 md:-skew-y-6 glow">
            <div className='md:pt-2 md:p-8 lg:p-12 skew-x-3 skew-y-3 md:skew-x-6 md:skew-y-6'>
              <h1 className='pl-12 font-bold md:pl-2 lg:pl-0 font-hello-denver text-4xl lg:text-7xl -skew-y-3 md:-skew-y-6'>LUCA DASIC</h1>
              <h2 className='pl-12 font-semibold md:pl-2 lg:pl-0 text-xl lg:text-3xl -skew-y-3 md:-skew-y-6 pb-8 md:pb-16'>Software Engineer</h2>
              <p className='px-12 md:px-0 text-sm md:text-base lg:text-xl'>At 20 years old, I embarked on an incredible journey, immigrating to Canada with $3000 to my name. This experience shaped my resilience and determination - the same qualities I carry in my work every day.</p>
              <br/>
              <p className='px-12 md:px-0 text-sm md:text-base lg:text-xl'>When you work with me, you're partnering with someone who's been in the trenches, faced challenges head-on, and emerged stronger. My commitment is to bring this same tenacity and determination to help your business thrive.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
