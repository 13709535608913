import React from 'react';
import HeroSection from './components/HeroSection';
import ProjectSection from './components/ProjectSection';
import SignatureSection from './components/SignatureSection';

const playbookProjectLogos = [
  { src: '/brands/nextjs-logotype-dark-background.svg', alt: 'NextJS' },
  { src: '/brands/ts-lettermark-blue.svg', alt: 'TypeScript' },
  { src: '/brands/vercel-logotype-light.svg', alt: 'Vercel' },
  { src: '/brands/azure-logo.svg', alt: 'Microsoft Azure' },
  { src: '/brands/azure-sql.png', alt: 'Azure SQL', extraClasses: 'max-h-12 md:max-w-14 md:h-auto' },
  { src: '/brands/google-analytics-logo.png', alt: 'Google Analytics' },
];

function App() {
  return (
    <div className='bg-brand-black'>
      <HeroSection />

      <ProjectSection
        title='My Playbook - Food & Drink offers in London and Dubai'
        link='https://my-playbook.com'
        imageSrc='/my-playbook.png'
        altText='My Playbook'
        logos={playbookProjectLogos}
      />

      <div className='flex w-full justify-center pb-16'>
        <a
          href="https://docs.google.com/forms/d/12ax4y7WsQuIkGwxKUNgECpjIfZ9zziyRq92RIeAextY"
          target="_blank"
          rel="noreferrer"
          className='rounded-full bg-white text-brand-black py-3 px-10 font-bold streak-animation'
        >
          GET IN TOUCH
        </a>
      </div>

      <SignatureSection />
    </div>
  );
}

export default App;
