import React from 'react';
import PropTypes from 'prop-types';

const ProjectSection = ({ title, link, imageSrc, altText, logos }) => {
  return (
    <div className="flex flex-col min-h-screen bg-brand-black justify-center items-center text-white p-8 md:p-16">
      <h2 className='text-3xl font-bold mb-8'>{title}</h2>
      
      <a href={link} target='_blank' rel='noreferrer' className='w-full xl:w-1/3'>
        <img src={imageSrc} alt={altText} className='rounded-xl w-full white-shadow'/>
      </a>

      <div className='flex flex-col md:flex-row md:flex-wrap justify-center items-center space-y-8 md:space-y-0 w-full mt-8 px-28 md:px-0'>
        {logos.map((logo, index) => (
          <img key={index} src={logo.src} alt={logo.alt} draggable="false" className={'md:w-36 md:m-8 lg:m-10 select-none' + ' ' + (logo.extraClasses ? logo.extraClasses : '') } />
        ))}
      </div>
    </div>
  );
}

ProjectSection.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      extraClasses: PropTypes.string,
    })
  ).isRequired,
};

export default ProjectSection;