import React, { useEffect, useState } from 'react';

const SignatureSection = () => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const isBottom = window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight;
    setIsBottom(isBottom);

    if(isBottom) {
      window.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className='h-60'>
      {isBottom ? (
        <img
          src={'/signature.svg?time=' + new Date().getTime()}
          alt='Signature'
          draggable='false'
          className='w-1/2 md:w-1/3 xl:w-1/4 max-w-[400px] mx-auto py-8'
        />
      ) : null}
    </section>
  );
};

export default SignatureSection;